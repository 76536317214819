export const undefinifyObjects = (obj: Record<string, any>): any => {
  return Object.entries(obj).reduce((o, curr) => {
    if (!!curr[1]) {
      o[curr[0]] = curr[1];
    }
    return o;
  }, {});
};

export const oKeys = <T>(obj: T): (keyof T)[] => {
  return Object.keys(obj) as (keyof T)[];
};

export const oFromEntries = <T extends string, K>(arr: [T, K][]): Record<T, K> => {
  return Object.fromEntries(arr) as Record<T, K>;
};
